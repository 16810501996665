
//import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../bus";

export default defineComponent({
  name: "trainee_bank_info_update",
  components: {},
  props: {
    data: { type: Object },
  },
  data() {
    return {
      bankType: false,
    };
  },
  methods: {
    changeType() {
      this.bankType = true;
    },
  },
  setup() {
    const formData = ref({
      nid: "",
      id: "",
      bd: "",
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const addModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const update = ref<boolean>(false);
    const rules = ref({
      //   code: [
      //     {
      //       required: true,
      //       message: "Code is required",
      //       trigger: "change",
      //     },
      //   ],
      //   bg: [
      //     {
      //       required: true,
      //       message: "Blood Group is required",
      //       trigger: "change",
      //     },
      //   ],
    });
    const { bus } = useBus();

    bus.on("edit-modal-data", (data) => {
      update.value = true;
      formData.value = data;
    });

    bus.on("add-modal-data", () => {
      update.value = false;
      formData.value = {
        nid: "",
        id: "",
        bd: "",
      };
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          Swal.fire({
            text: "Updated",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            hideModal(addModalRef.value);
          });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addModalRef,
    };
  },
});
